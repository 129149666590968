"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PIN_TYPE = exports.POI_TYPE = void 0;
var POI_TYPE;
(function (POI_TYPE) {
    POI_TYPE["Address"] = "Address";
    POI_TYPE["Coords"] = "Coords";
    POI_TYPE["POI"] = "POI";
    POI_TYPE["Stop"] = "Stop";
})(POI_TYPE = exports.POI_TYPE || (exports.POI_TYPE = {}));
var PIN_TYPE;
(function (PIN_TYPE) {
    PIN_TYPE["stop"] = "stop";
    PIN_TYPE["platform"] = "platform";
    PIN_TYPE["poi"] = "poi";
    PIN_TYPE["rentabike"] = "rentabike";
    PIN_TYPE["ticketmachine"] = "ticketmachine";
    PIN_TYPE["carsharing"] = "carsharing";
    PIN_TYPE["parkandride"] = "parkandride";
    PIN_TYPE["unknown"] = "unknown";
})(PIN_TYPE = exports.PIN_TYPE || (exports.PIN_TYPE = {}));
